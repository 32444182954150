import React, { useEffect, useRef } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { Flow, FlowHandleRef } from './components/Flow/Flow';
import './App.scss';


function App() {
  const flowRef = useRef<FlowHandleRef>();

  useEffect(() => {
    // (window as any)._postMessage = window.postMessage;
    // window.postMessage = () => {}

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    }
  }, []);

  const onMessage = (event: MessageEvent) => {
    const message = event.data;
    if (!message.type) {
      return;
    }

    if (message.type === 'contents' && message.contents) {
      flowRef.current?.loadContent(message.contents);
    }
  }

  return (
    <div className="App">
      <ReactFlowProvider>
        <Flow ref={flowRef}/>
      </ReactFlowProvider>
    </div>
  );
}

export default App;
