import React from 'react';
import './Toolbar.scss';
import { useReactFlow } from 'reactflow';

export enum NodeType {
  MAIN_METER = 'main-meter',
  BUILDING_METER = 'building-meter',
  SUB_METER = 'sub-meter',
  SHARED_METER = 'shared-meter',
  DIFFERENTIAL = 'differential',
  RENTER1 = 'renter1',
  RENTER2 = 'renter2',
  RENTER3 = 'renter3',
}

export type SaveTarget = 'file' | 'parent';

type Props = {
  onNewNode?: (nodeType: NodeType) => void,
  onSave?: (target: SaveTarget) => void,
  onLoad?: () => void,
  onSaveImage?: () => void,
};

export const Toolbar: React.FC<Props> = ({ onNewNode, onSave, onLoad, onSaveImage }) => {

  const { getNodes }  = useReactFlow();

  const newNode = (nodeType: NodeType) => {
    if (onNewNode) {
      onNewNode(nodeType);
    }
  }

  const loadFromFile = () => {
    if (onLoad) {
      onLoad();
    }
  }

  const saveToFile = () => {
    if (onSave) {
      onSave('file');
    }
  }

  const saveToParentContainer = () => {
    if (onSave) {
      onSave('parent');
    }
  }

  const downloadAsImage = () => {
    if (getNodes().length === 0) {
      return alert('Még nincs egy csomópont sem felrakva!');
    }

    if (onSaveImage) {
      onSaveImage();
    }
  }

  return (
    <div className="Toolbar">
      <section>
        <h2>Csomópontok</h2>

        <ul>
          <li>
            <button
              className="main-meter btn"
              onClick={() => newNode(NodeType.MAIN_METER)}>
              Főmérő
            </button>
          </li>
          <li>
            <button
              className="building-meter btn"
              onClick={() => newNode(NodeType.BUILDING_METER)}>
              Épületszintű mérő
            </button>
          </li>
          <li>
            <button
              className="sub-meter btn"
              onClick={() => newNode(NodeType.SUB_METER)}>
              Almérő
            </button>
          </li>
          <li>
            <button
              className="shared-meter btn"
              onClick={() => newNode(NodeType.SHARED_METER)}>
              Közös
            </button>
          </li>
          <li>
            <button
              className="differential btn"
              onClick={() => newNode(NodeType.DIFFERENTIAL)}>
              Különbözet
            </button>
          </li>
          <li>
            <button
              className="renter-1 btn"
              onClick={() => newNode(NodeType.RENTER1)}>
              Bérlő 1
            </button>
          </li>
          <li>
            <button
              className="renter-2 btn"
              onClick={() => newNode(NodeType.RENTER2)}>
              Bérlő 2
            </button>
          </li>
          <li>
            <button
              className="renter-3 btn"
              onClick={() => newNode(NodeType.RENTER3)}>
              Bérlő 3
            </button>
          </li>
        </ul>
      </section>

      <section>
        <h2>Műveletek</h2>

        <ul>
          <li>
            <button className="btn-save btn primary" onClick={saveToParentContainer}>Mentés programba</button>
          </li>
          <li>
            <button className="btn-save btn primary" onClick={saveToFile}>Mentés fájlba</button>
          </li>
          <li>
            <button className="btn-load btn primary" onClick={loadFromFile}>Betöltés fájlból</button>
          </li>
          <li>
            <button className="btn-load btn primary" onClick={downloadAsImage}>Letöltés képként</button>
          </li>
        </ul>
      </section>
    </div>
  );
}