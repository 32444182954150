import { toPng } from "html-to-image";
import { Transform } from "reactflow";


export const downloadImage = (transform: Transform, width: number, height: number) => {

  toPng(document.querySelector('.react-flow__viewport') as HTMLElement, {
    backgroundColor: '#efefef',
    width,
    height,
    style: {
      width: `${width}px`,
      height: `${height}px`,
      transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`
    }
  }).then((dataUrl: string) => {
    const a = document.createElement('a');

    a.setAttribute('download', 'koltsegfelosztas.png');
    a.setAttribute('href', dataUrl);
    a.click();
  }).catch(err => {
    alert('Hiba történt a kép letöltése közben!');
    console.log(err);
  })
}